import { formatDateISO, formatDateShort } from '@helpers/date';
import { Device, until } from '@helpers/media';
import { ReactComponent as Chevron } from '@img/icons/chevron.svg';
import Image from '@stories/Components/Media/Image/Image';
import React from 'react';
import { DocumentCardStyles } from './DocumentCard.styles';
import { ThemeWrapper } from '@helpers/theme';
const DocumentCard = React.forwardRef((props, ref) => {
    return (React.createElement(ThemeWrapper, { "data-theme": props.theme },
        React.createElement(DocumentCardStyles.Card, { ref: ref, href: props.document.url, draggable: false, isLatestNewsWidget: props.isLatestNewsWidget, as: props.document.url ? 'a' : 'div' },
            React.createElement(DocumentCardStyles.TagArea, null,
                props.document.noticeTag ? (React.createElement(DocumentCardStyles.NoticeTag, null, props.document.noticeTag)) : null,
                props.document.groundforceDivision &&
                    props.document.groundforceDivision.name.length > 0 && (React.createElement(DocumentCardStyles.DivisionTag, { "data-tag-colour": props.document.groundforceDivision.class }, props.document.groundforceDivision.name))),
            React.createElement(DocumentCardStyles.Media, { isLatestNewsWidget: props.isLatestNewsWidget }, props.Image ? (React.createElement(props.Image, null)) : (React.createElement(Image, { layout: "fill", src: props.document.imageUrl, sizes: `${until(Device.TabletLarge)} 50vw, ${until(Device.DesktopLarge)} 33vw, 25vw`, objectFit: "cover", alt: props.document.title || '', draggable: false, fullPaddingTop: props.document.fullPaddingTop }))),
            React.createElement(DocumentCardStyles.Content, null,
                props.document.date && (React.createElement(DocumentCardStyles.Date, { as: "time", dateTime: formatDateISO(props.document.date) }, formatDateShort(props.document.date))),
                props.document.tag && (React.createElement(DocumentCardStyles.Tag, null, props.document.tag)),
                React.createElement(DocumentCardStyles.Title, { isLatestNewsWidget: props.isLatestNewsWidget }, props.document.title),
                React.createElement(DocumentCardStyles.ParagraphContent, { size: "small", isLatestNewsWidget: props.isLatestNewsWidget }, props.document.description),
                props.document.url && (React.createElement(DocumentCardStyles.Icon, null,
                    React.createElement(Chevron, null)))))));
});
DocumentCard.displayName = 'DocumentCard';
export default DocumentCard;
