import brand from '@helpers/brand';
import { FlickingCss } from '@helpers/flickingStyles';
import { fluid } from '@helpers/fluid';
import { Device, from, until } from '@helpers/media';
import { ThemeSwitch } from '@helpers/theme';
import { DocumentCardStyles } from '@stories/Components/Cards/DocumentCard/DocumentCard.styles';
import HeadingStyles from '@stories/Components/Global/Typography/Heading.styles';
import styled from 'styled-components';
const Wrapper = styled.div `
  margin: 50px 0px;
  overflow: hidden;

  ${HeadingStyles.Heading} {
    grid-column: span 12;
    text-align: center;
  }
`;
const Inner = styled.div `
  grid-column: span 12;
  --slide-gap: 5px;
  --slide-size: 65vw;

  @media ${from(Device.MobileLarge)} {
    --slide-size: 44%;
  }

  @media ${from(Device.Tablet)} {
    --slide-gap: 15px;
    --slide-size: 31%;
  }

  @media ${from(Device.Desktop)} {
    --slide-size: 25%;
  }

  ${HeadingStyles.Heading} {
    ${ThemeSwitch({ groundforceColour: brand.primary.alt })};
    color: var(--theme);
  }
`;
const Slider = styled.div `
  ${FlickingCss};
  position: relative;
  left: calc(-1 * var(--slide-gap));
  width: calc(100% + var(--slide-gap));

  @media ${until(Device.Desktop)} {
    .flicking-viewport {
      overflow: visible;
    }
  }
`;
const SlideItem = styled.div `
  width: calc(var(--slide-size) + ((var(--slide-gap) / 4) - var(--slide-gap)));
  padding: var(--slide-gap);

  @media ${from(Device.MobileLarge)} {
    min-width: 44%;
  }

  @media ${from(Device.TabletLarge)} {
    min-width: 31%;
  }

  @media ${from(Device.Desktop)} {
    min-width: 25%;
  }

  ${DocumentCardStyles.Card} {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  ${DocumentCardStyles.Content} {
    flex: 1;
  }
`;
const CarouselControls = styled.div `
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding-right: var(--slide-gap);

  margin-top: ${fluid(10, 15, Device.Mobile, Device.TabletLarge)};
`;
export const DocumentListStyles = {
    Wrapper,
    Inner,
    Slider,
    SlideItem,
    CarouselControls,
};
