import brand from '@helpers/brand';
import { Device, from, until } from '@helpers/media';
import styled, { css } from 'styled-components';
import { ThemeSwitch } from '@helpers/theme';
const PrimaryStyles = css `
  color: ${brand.white};
  ${ThemeSwitch({ groundforceColour: brand.primary.base, groundforceColourHover: '#a2b952' })}
  background-color: var(--theme-hover);

  &:hover {
    background-color: var(--theme);
    color: ${brand.white};
  }

  &:active,
  &:focus {
    color: ${brand.white};
  }
`;
const PrimaryAltStyles = css `
  color: ${brand.white};
  ${ThemeSwitch({ groundforceColour: brand.primary.alt })}
  background-color: var(--theme);

  &:hover {
    color: ${brand.white};
  }

  &:active,
  &:focus {
    color: ${brand.white};
  }
`;
const Button = styled.button `
  display: inline-flex;
  align-items: center;
  border: 0;
  ${ThemeSwitch({ groundforceColour: brand.primary.base })}
  background-color: var(--theme);
  color: ${brand.white};
  height: 48px;
  justify-content: flex-start;
  margin: 0;
  outline: none;
  padding: 0;
  place-content: center;
  place-items: center;
  text-decoration: none;
  transition: background-size 0.15s ease-out, opacity 0.15s ease-out, transform 0.15s ease-out;
  user-select: none;
  width: 48px;

  &[data-theme='primary'] {
    ${PrimaryStyles}
  }
  &[data-theme='primaryAlt'] {
    ${PrimaryAltStyles}
  }

  &:disabled {
    opacity: 0.5;
  }

  &:focus {
    outline: none;
  }

  @media ${from(Device.Tablet)} {
    &:hover {
      opacity: 0.7;
    }
  }

  @media ${until(Device.Tablet)} {
    height: 40px;
    width: 40px;
  }
`;
export default {
    Button,
};
