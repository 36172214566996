import { HydrateOption } from '@core/enums';
import Flicking, { ALIGN } from '@egjs/react-flicking';
import { Grid } from '@helpers/grid';
import withWidget from '@hoc/withWidget';
import DocumentCard from '@stories/Components/Cards/DocumentCard/DocumentCard';
import Heading from '@stories/Components/Global/Typography/Heading';
import React, { useRef } from 'react';
import { DocumentListStyles } from './DocumentList.styles';
import { ThemeWrapper } from '@helpers/theme';
import PrevButton from '@stories/Components/Buttons/PrevButton/PrevButton';
import NextButton from '@stories/Components/Buttons/NextButton/NextButton';
const DocumentList = (props) => {
    const sliderRef = useRef(null);
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [showControls, setShowControls] = React.useState(false);
    return (React.createElement(ThemeWrapper, { "data-theme": props.theme },
        React.createElement(DocumentListStyles.Wrapper, null,
            React.createElement(Grid.Default, null,
                React.createElement(DocumentListStyles.Inner, null,
                    React.createElement(Heading, { visual: "h5", semantic: "h2" }, props.title),
                    React.createElement(DocumentListStyles.Slider, null,
                        React.createElement(Flicking, { ref: sliderRef, align: ALIGN.PREV, onReady: (event) => {
                                const visiblePanels = getVisiblePanels(event.currentTarget);
                                // Hide controls when all panels are visible.
                                setShowControls(!(visiblePanels.length === props.documents.length));
                            }, onWillChange: (event) => {
                                if (event.index > props.documents.length - 1) {
                                    setActiveIndex(event.index - props.documents.length);
                                }
                                else {
                                    setActiveIndex(event.index);
                                }
                            } }, props.documents.map((x, i) => (React.createElement(DocumentListStyles.SlideItem, { key: i },
                            React.createElement(DocumentCard, { key: i, document: x, theme: props.theme })))))),
                    showControls && (React.createElement(DocumentListStyles.CarouselControls, null,
                        React.createElement(PrevButton, { disabled: sliderRef.current?.index === 0 || activeIndex === 0, themeOption: "primaryAlt", onClick: (event) => {
                                event.preventDefault();
                                sliderRef.current?.prev();
                            } }),
                        React.createElement(NextButton, { disabled: sliderRef.current?.index === props.documents.length - 1 ||
                                activeIndex === props.documents.length - 1, themeOption: "primaryAlt", onClick: (event) => {
                                event.preventDefault();
                                sliderRef.current?.next();
                            } }))))))));
};
export default withWidget(DocumentList, 'DocumentList', { hydrate: HydrateOption.InView });
function getVisiblePanels(instance) {
    return instance.panels.filter((panel) => panel.visibleRatio >= 0.9);
}
